import React from "react";

const Header = () => {
	return (
		<header>
			<img src='http://www.pngall.com/wp-content/uploads/2016/05/Trollface.png' alt='img-logo' />
			<p>Meme Generator</p>
		</header>
	);
};

export default Header;
